import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";

export default function(props) {
  return (<Row>
    <Col sm="12" className="pl-5 pr-5">
      <Card>
        <CardHeader className="center">
          <h5 className="mb-2">Go Pro!</h5>
          <p>
            Become a member and have say in the future of Liquidity.Vision! By holding sufficient
            VISION tokens, you will unlock our professional suite of tools for liquidity providers!
            You can cancel your membership any time by releasing your tokens back to the bonding
            curve. Token holders are able to vote on new features!
          </p>
          <p>
            Please note that the VISION governance tokens are inherently valueless and should not be
            used for speculative/investment purposes.
          </p>
        </CardHeader>
        <CardBody className="pricing-card-design-3">
          <Row className="pricing-content-ribbons justify-content-center">
            <Col md="5" sm="12">
              <Card className="pricing-block text-center">
                <svg x="0" y="0" viewBox="0 0 360 220">
                  <g>
                    <path fill="#0099ff" fill-opacity="1"
                          d="M-247.74,273.84 C-221.22,345.88 271.49,-49.98 712.75,408.05 L563.20,-91.28 L0.00,0.00 Z"></path>
                  </g>
                  <text
                    transform="matrix(1 0 0 1 100 116.2686)"
                    fill="#fff"
                    fontSize="60"
                  >
                    FREE
                  </text>
                </svg>
                <div className="pricing-inner mt-3 mb-5">
                  <h3 className="font-primary mb-3">Basic</h3>
                  <ul className="pricing-inner">
                    <li>
                      <h6>
                        Nothing changes for you! Enjoy your current experience! ❤️
                      </h6>
                    </li>
                  </ul>
                </div>
              </Card>
            </Col>
            <Col md="5" sm="12">
              <Card className="pricing-block text-center">
                <div className="ribbon ribbon-bookmark ribbon-danger">
                  Hold In Wallet To Activiate
                </div>
                <svg x="0" y="0" viewBox="0 0 360 220">
                  <g>
                    <path fill="#0099ff" fill-opacity="1"
                          d="M-247.74,273.84 C-221.22,345.88 271.49,-49.98 712.75,408.05 L563.20,-91.28 L0.00,0.00 Z"></path>
                  </g>
                  <text
                    transform="matrix(1 0 0 1 30.7256 96.2686)"
                    fill="#fff"
                    fontSize="40"
                  >
                    PROFESSIONAL
                  </text>
                  <text
                    transform="matrix(1 0 0 1 90.7256 135.5303)"
                    fill="#fff"
                    fontSize="15.4128"
                  >
                    More Info Coming Soon
                  </text>
                </svg>
                <div className="pricing-inner mt-3 mb-5">
                  <h3 className="font-primary mb-3">Pro</h3>
                  <ul className="pricing-inner pl-5 pr-5">
                    <li>
                      <h6>
                        Total APYs and Profits with Farming
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Real Time Price Quotes
                      </h6>
                    </li>

                    <li>
                      <h6>
                        Expedited Query Speeds
                      </h6>
                    </li>
                    <li>
                      <h6>
                        View Historical Profit/Loss History
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Remembering Wallet Addresses / History
                      </h6>
                    </li>
                    <li>
                      <h6>
                        View Pool Insights + Fee History
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Dark Mode Switch
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Daily Summary Emails/Alerts
                      </h6>
                    </li>
                    <li>
                      <h6>
                        View Outstanding LP Tokens Sent to Contracts
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Multiple Wallet Support
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Additional AMMs (VALUE, SakeSwap, Pancake, DODO, HoneySwap)
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Vote on New Features
                      </h6>
                    </li>
                  </ul>
                  <Button color="primary" size="lg btn-pill mt-3">
                    Coming Soon
                  </Button>
                </div>
              </Card>
            </Col>

            <Col md="12">
              <p className="mb-3 mt-3 centered">
                <a className="anchor-link"
                   onClick={() => window.open("https://gitcoin.co/grants/1268/liquidity-vision", "_blank")}>Have
                  a feature request or suggestion?</a>.
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>);
}
