import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import './index.scss';
import { Web3Provider } from '@ethersproject/providers';
import axios from 'axios';
import { useContractReader, useTokenBalance, useUserAddress } from 'eth-hooks';
import { toast, ToastContainer } from 'react-toastify';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethers } from 'ethers';
import Home from './components/pages/Home';
import Sidebar from './components/common/sidebar/sidebar';
import Header from './components/common/header/Header';
import Breadcrumb from './components/common/breadcrumb/breadcrumb';
import Footer from './components/common/footer/footer';
import { API_URL, INFURA_ID, LOGIN_URI } from './constants';
import { useUserProvider } from './hooks';
import Pricing from './components/pages/Pricing';
import ChartsPage from './components/pages/ChartsPage';
import FaqPage from './components/pages/FaqPage';
import ChangeLogPage from './components/pages/ChangeLogPage';
import useGetLiquidityVisionBalance from './hooks/UseGetLiquidityBalance';
import { getErrorFromCode, useLocalStorage } from './helpers/util';

const web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: false, // optional
  disableInjectedProvider: false,
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
      },
    },
  },
});

function App() {
  const [injectedProvider, setInjectedProvider] = useState();
  const [portfolio, setPortfolio] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [viewButtonText, setViewButtonText] = useState('View');
  const [shouldLogin, setShouldLogin] = useState();
  const [isPerformSearchOnLogin, setIsPerformSearchOnLogin] = useState();

  const [accessToken, setAccessToken] = useLocalStorage('accessToken');
  const userProvider = useUserProvider(injectedProvider, null);

  const addressFromWallet = useUserAddress(userProvider);
  console.log('💵 address ', addressFromWallet || '...');

  const visionBalance = useGetLiquidityVisionBalance(injectedProvider);
  console.log('💵 visionBalance ', visionBalance);

  const connectWeb3Modal = async () => {
    try {
      const provider = await web3Modal.connect();
      provider.on('disconnect', (error) => {
        throw 'disconnected';
      });
      setInjectedProvider(new Web3Provider(provider));
    } catch (e) {
      console.log('onerror' + e);
    }
  };

  async function getInfo(addrs) {
    setViewButtonText('Loading...');
    const item = window.localStorage.getItem(`excluded_pools_${addrs}`);
    const excludedPoolAddresses = item ? JSON.parse(item) : '';
    try {
      setCurrentAddress(addrs);
      const response = await axios.get(`${API_URL}/portfolio/${addrs}?excludedPools=${excludedPoolAddresses}`);
      setPortfolio(response.data);
      setViewButtonText('View');
    } catch (err) {
      console.log(err);
      toast.error(getErrorFromCode('SERVER_BUSY'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 6000,
      });
      setViewButtonText('Try Again');
    }
  }

  async function login(message, signature) {
    try {
      const result = await axios.put(API_URL + LOGIN_URI, {
        message,
        signature,
        address: addressFromWallet,
      });
      const data = result.data;
      return data.accessToken;
    } catch (error) {
      const msg = error.response ? getErrorFromCode(error.response.data.error) : error.message;
      toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 6000,
      });
      return null;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (injectedProvider && shouldLogin && typeof visionBalance !== 'undefined') {
        setShouldLogin(false);

        if (visionBalance === 0) {
          toast.error(getErrorFromCode('NO_TOKEN_IN_WALLET'), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 6000,
          });
          return;
        }

        const timestamp = new Date().toGMTString();
        console.log('injectedProvider', injectedProvider);
        console.log('Owner Address', addressFromWallet);
        const message = `Liquidity Vision Login at ${timestamp}`;
        console.log('message', message);

        const signer = injectedProvider.getSigner();
        console.log('Owner Address', addressFromWallet);
        const signature = await signer.signMessage(message);
        const publicAddrs = await ethers.utils.verifyMessage(message, signature);

        if (addressFromWallet === publicAddrs) {
          const token = await login(message, signature);
          setAccessToken(token);
        } else {
          toast.error(getErrorFromCode('ADDRESS_MISMATCH'), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 6000,
          });
        }
      }
    };
    fetchData();
  }, [injectedProvider, shouldLogin, addressFromWallet, visionBalance]);

  async function refreshPorfolio() {
    setPortfolio(null);
    await getInfo(currentAddress);
  }

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    // console.log("Cleared cache provider!?!",clear)
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (addressFromWallet && isPerformSearchOnLogin) {
        await getInfo(addressFromWallet);
      }
    };

    fetchData();
  }, [addressFromWallet, isPerformSearchOnLogin]);

  const proLogIn = async () => {
    await connectWeb3Modal();
    setShouldLogin(true);
  };

  const performSearchViaProvider = async () => {
    await connectWeb3Modal();
    setIsPerformSearchOnLogin(true);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header injectedProvider={injectedProvider}
                  hasResults={!!portfolio}
                  refreshPorfolio={refreshPorfolio}
                  proLogIn={proLogIn}
                  logout={logoutOfWeb3Modal}/>
          <Sidebar/>
          <div className="page-body">
            <Breadcrumb/>
            <Container fluid="true">
              <Switch>
                <Route exact path="/">
                  <Home addressFromWallet={addressFromWallet}
                        portfolio={portfolio}
                        setPortfolio={setPortfolio}
                        viewButtonText={viewButtonText}
                        injectedProvider={injectedProvider}
                        performSearchViaProvider={performSearchViaProvider}
                        getInfo={getInfo}/>
                </Route>
                <Route path="/pricing">
                  <Pricing/>
                </Route>
                <Route path="/charts">
                  <ChartsPage/>
                </Route>
                <Route path="/faq">
                  <FaqPage/>
                </Route>
                <Route path="/changelog">
                  <ChangeLogPage/>
                </Route>
              </Switch>
            </Container>
          </div>
          <Footer/>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}

export default App;
