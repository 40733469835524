import React from "react";
import { Button } from "reactstrap";

export default function GasGauge(props) {
  return (
    <Button
      outline
      color="info"
      className="btn-outline-info ml-3"
      onClick={() => {
        window.open("https://ethgasstation.info/", "_blank");
      }}
    >
      <span style={{ marginRight: 8 }}>⛽️</span>
      {parseInt(props.gasPrice, 10) / 10 ** 9} gwei
    </Button>
  );
}
