import React, { useState } from "react";
import PctGainLossSpan from "./PctGainLossSpan";
import { Card, CardBody, Col, Row, Tooltip } from "reactstrap";
import CurrencyFormat from "react-currency-format";
import { ExternalLink, HelpCircle } from 'react-feather';
import TokenAddressAndPrice from "./TokenAddressAndPrice";
import {
  getAddLiquidityLink, getGainPctStr,
  getPoolInformation,
  getRemoveLiquidityLink, getSwapLink,
  toDecimalPlaces,
} from "../helpers/util";

export default function(props) {

  const entry = props.entry;

  function startingAssetsCurrentPrice() {
    let total = 0;
    entry.tokens.forEach((value) => (total += value.tokenStartingBalance * value.tokenCurrentPrice));
    return total.toFixed(2);
  }

  const [tooltip0, setTooltip0] = useState(false);
  const DirectionToggle0 = () => setTooltip0(!tooltip0);

  const [tooltip1, setTooltip1] = useState(false);
  const DirectionToggle1 = () => setTooltip1(!tooltip1);

  const [tooltip2, setTooltip2] = useState(false);
  const DirectionToggle2 = () => setTooltip2(!tooltip2);

  function renderTokenPrices() {
    return <TokenAddressAndPrice entry={entry}/>;
  }

  function renderInitialAssets() {
    let string = "";
    entry.tokens.forEach((token) => {
      string += `${toDecimalPlaces(token.tokenStartingBalance)} ${token.tokenName} / `;
    });
    return <>{string.slice(0, -2)}</>;
  }

  function renderCurrentAssets() {
    let string = "";
    entry.tokens.forEach((token) => {
      string += `${toDecimalPlaces(token.tokenCurrentBalance)} ${token.tokenName} / `;
    });
    return <>{string.slice(0, -2)}</>;
  }

  return (
    <>
      <Card className="btc-table">
        <CardBody className="pt-0">
          <div className="btc-sell text-center">
            <h4>
              <a className="anchor-link text-center block"
                 onClick={() => window.open(getPoolInformation(entry), "_blank")}>
                {entry.name} Pool Information
                <ExternalLink className="external-link-icon"/>
              </a>
            </h4>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col sm="12" className="p-l-0 btc-table-xs-l">
                  {renderTokenPrices()}
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col sm="12" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount  f-w-700 text-center mb-1">
                    Your share of the pool
                    - {toDecimalPlaces(entry.mintBurntLedgerLpTokens)} LP Tokens
                    ({(entry.ownedLpTokensPct).toFixed(8)}%)
                  </div>
                </Col>
              </Row>
            </li>

          </ul>
          <div className="btc-sell text-center">
            <h5>Your Assets (in USD)</h5>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Initial Assets
                    <a
                      id="Tooltip-2"
                      className="badge badge-pill badge-light tooltip-stats"
                    >
                      <HelpCircle/>
                      <Tooltip
                        placement="top"
                        isOpen={tooltip2}
                        target="Tooltip-2"
                        toggle={DirectionToggle2}
                        offset="20px,20px"
                      >
                        This is the assets you have initially. Please note that one side of the
                        assets could be negative if you removed some liquidity but stayed in the
                        pool.
                      </Tooltip>
                    </a>

                  </div>
                </Col>
                <Col sm="7" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    {renderInitialAssets()}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Current Assets
                  </div>
                </Col>
                <Col sm="7" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    {renderCurrentAssets()}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Initial Assets at Initial Prices
                  </div>
                </Col>
                <Col sm="4" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    <CurrencyFormat value={entry.initialCapitalValueUsd} displayType="text"
                                    decimalScale={2}
                                    prefix="$" thousandSeparator>
                      USD
                    </CurrencyFormat>
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Initial Assets at Current Prices
                  </div>
                </Col>
                <Col sm="4" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700  text-center block">
                    <CurrencyFormat value={startingAssetsCurrentPrice()} displayType="text"
                                    decimalScale={2}
                                    prefix="$" thousandSeparator>
                      USD
                    </CurrencyFormat>
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Current Assets at Current Prices
                  </div>
                </Col>
                <Col sm="4" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700  text-center block">
                    <CurrencyFormat value={entry.totalValueUsd} displayType="text"
                                    decimalScale={2}
                                    prefix="$" thousandSeparator>
                      USD
                    </CurrencyFormat>
                  </div>
                </Col>
              </Row>
            </li>
          </ul>

          <div className="btc-sell text-center">
            <h5>Your Gains/Losses (in USD)</h5>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Total Gas Fees Paid
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    ${entry.totalFeeUsd.toFixed(2)}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Net Liquidity Pool Gains
                    <a
                      id="Tooltip-0"
                      className="badge badge-pill badge-light tooltip-stats"
                    >
                      <HelpCircle/>
                      <Tooltip
                        placement="top"
                        isOpen={tooltip0}
                        target="Tooltip-0"
                        toggle={DirectionToggle0}
                        offset="20px,20px"
                      >
                        This is the total profit/loss of your liquidity pool positions factoring in
                        transaction costs and swap fees.
                      </Tooltip>
                    </a>
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    <PctGainLossSpan
                      className="plain" prefix="$"
                      val={entry.netGainUsd}>{getGainPctStr(entry.netGainPct, true)}
                    </PctGainLossSpan>
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Net Gains w/ Market Prices
                    <a
                      id="Tooltip-1"
                      className="badge badge-pill badge-light tooltip-stats"
                    >
                      <HelpCircle/>
                      <Tooltip
                        placement="top"
                        isOpen={tooltip1}
                        target="Tooltip-1"
                        toggle={DirectionToggle1}
                        offset="20px,20px"
                      >
                        This is your total profit/loss including market
                        conditions. Simply put, this is the increase or decrease
                        in the value of your assets including the normal price fluctuations of the
                        market since you entered the pool.
                      </Tooltip>
                    </a>
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    <PctGainLossSpan
                      className="plain" prefix="$"
                      val={entry.totalValueUsd - entry.initialCapitalValueUsd}>{getGainPctStr(((entry.totalValueUsd - entry.initialCapitalValueUsd) / entry.initialCapitalValueUsd) * 100, true)}
                    </PctGainLossSpan>
                  </div>
                </Col>
              </Row>
            </li>
          </ul>
          <div className="btc-buy text-center">
            <div className="mt-4">
              <button
                onClick={() => window.open(getSwapLink(entry), "_blank")}
                className="btn btn-primary btn-lg txt-white btn-pill"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                Swap
              </button>
            </div>
            <div className="mt-3">
              <button
                onClick={() => window.open(getAddLiquidityLink(entry), "_blank")}
                className="btn btn-primary btn-lg txt-white btn-pill"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                Add Liquidity
              </button>
            </div>
            <div className="mt-3">
              <button
                onClick={() => window.open(getRemoveLiquidityLink(entry), "_blank")}
                className="btn btn-primary btn-lg txt-white btn-pill"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                Remove Liquidity
              </button>
            </div>
            <div className="mt-3 m-b-30">
              <button
                onClick={() => props.setExcludeModal(true)}
                className="btn btn-air-info btn-lg btn-pill"
                type="button"
                aria-label="Close"
              >
                Exclude Pool
              </button>
            </div>
          </div>

        </CardBody>
      </Card>
    </>
  );
}
