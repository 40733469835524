import React from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, CardHeader, Alert } from "reactstrap";

const Breadcrumbs = (props) => {
  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6" sm="12" className="main-header">
            <h6 className="mb-0">Track your liquidity pool profits with Liquidity.vision!</h6>
          </Col>
          <Col lg="6" sm="12" className="main-header">
            {/*<a className="anchor-link"*/}
            {/*   onClick={() => window.open("https://twitter.com/liquidityvision", "_blank")}>*/}
            {/*  <img*/}
            {/*    className="banner-ad"*/}
            {/*    src={require("../../../assets/images/liq_ad.png")}*/}
            {/*    alt=""*/}
            {/*  />*/}
            {/*</a>*/}
          </Col>
        </Row>

      </div>
    </Container>
  );
};

export default Breadcrumbs;
