import React, { useEffect, useState } from 'react';
import {
  Form,
  Alert,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
  TabContent,
  FormGroup,
  TabPane,
} from 'reactstrap';
import DisplayTable from '../DisplayTable';
import { HelpCircle } from 'react-feather';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TopPoolsHelpModal } from '../modals/TopPoolsHelpModal';

export default function(props) {
  const [hTabsIcons, setHTabsIcons] = useState('hTabsIcons-2');
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const options = [
    { name: 'Pool Age > 7 Days' },
    { name: 'Pool Reserves > $250000 USD' },
    { name: 'Pool Volume (24H) > $50000 USD' },
    { name: '+' },
  ];
  return (<Row>
    <Col xl="12">
      <Card className="card-absolute">
        <CardHeader className="bg-primary">
          <h5>Top Overall Pools</h5>
        </CardHeader>
        <CardBody className="small-padding">

          <Row className="justify-content-center">
            <Col className="col-7">
              <Alert className="alert-dismissible mt-3 mb-4"
                     color="info "
                     target="Alert-1"
              ><HelpCircle/><p>This is a Pro feature that is in preview - let us know if you have any
                suggestions!</p>
                <Button className="close" color="default" id="Alert-1"
                ><span>×</span></Button>
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col xl="12">

              <Card className="shadow">
                <CardBody className="small-padding">

                  <h6 className="center-block m-b-20">Time Period</h6>

                  <Row className="justify-content-center">

                    <Col xl="12">
                      <ButtonGroup className="centered-button-group">
                        <Button
                          className={'time-period-btn btn-pill ' + (hTabsIcons === 'hTabsIcons-2' ? 'btn-air-primary' : 'btn-air-light')}
                          color={hTabsIcons === 'hTabsIcons-2' ? 'primary' : 'light'}
                          onClick={(e) => {
                            e.preventDefault();
                            setHTabsIcons('hTabsIcons-2');
                          }}>
                          Since Pool Inception
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col xl={8} className="mt-5">
                      <h6 className="center-block m-b-20">
                        Search Criteria
                        &nbsp;<a onClick={() => setHelpModalOpen(true)}>
                          <HelpCircle size="20" className="help-bubble"/>
                        </a>
                      </h6>
                      <Form className="theme-form custom">
                        <FormGroup>
                          <span
                            className="badge badge-primary pro-feature-badge">PRO</span>
                          <Typeahead
                            id="multiple-typeahead"
                            clearButton
                            defaultSelected={options.slice(0, 5)}
                            labelKey="name"
                            multiple
                            disabled
                            options={options}
                            placeholder="Choose a state..."
                          />
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                  <TabContent id="myTabContent" activeTab={hTabsIcons}>
                    <TabPane tabId="hTabsIcons-2" role="tabpanel">
                      <DisplayTable setHelpModalOpen={setHelpModalOpen}/>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
    <TopPoolsHelpModal setModalOpen={setHelpModalOpen} isOpen={helpModalOpen}/>
  </Row>);
}
