import React, { useEffect } from 'react';
import { Button } from 'reactstrap';

export default function(props) {
  return (
    <Button onClick={() => {
      props.proLogIn();
    }} className="btn-lg btn-pill btn-air-info" color="info">
      Pro Log In
    </Button>
  );
}
