import React, { useState } from 'react';
import {
  Button,
} from 'reactstrap';
import { ArrowRight } from 'react-feather';
import PoolDetailModal from './modals/PoolDetailModal';

export function PairInfo(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <Button
        className="btn-pill more-info"
        color="primary"
        onClick={toggle}>
        <ArrowRight/>
      </Button>

      <PoolDetailModal poolInfo={props.poolInfo} modal={modal} toggle={toggle}
                       provider={props.provider}/>
    </>
  );
}
