module.exports = [
  // balanceOf
  {
    constant: true,
    inputs: [{ 'name': '_owner', 'type': 'address' }],
    name: 'balanceOf',
    outputs: [{ 'name': 'balance', 'type': 'uint256' }],
    type: 'function',
  },
];
