import React, { useEffect, useState } from 'react';
import { Button, CardBody, Tooltip, Modal, ModalBody, ModalHeader } from 'reactstrap';
import PctGainLossSpan from './PctGainLossSpan';
import {
  floorFloat, getGainPctStr,
  getPoolIcon,
  getPoolInformation,
  toDecimalPlaces,
  useLocalStorage,
} from '../helpers/util';
import GainLossDetailModal from './modals/GainLossDetailModal';
import { BarChart, HelpCircle, Info, MinusCircle } from 'react-feather';
import CurrencyFormat from 'react-currency-format';

function renderIsEstimated(entry) {
  if (floorFloat(entry.mintBurntLedgerLpTokens) - floorFloat(entry.currentOwnedLpTokens) !== 0) {
    return '*Est* ';
  }
  return null;
}

export default function(props) {

  const [modal, setModal] = useState(false);
  const [excludeModal, setExcludeModal] = useState(false);


  const toggle = () => {
    setModal(!modal);
  };

  function renderToken(token, renderHr = false) {
    const tokenName = token.tokenName.length > 6 ? token.tokenName.substring(0, 6) + '...' : token.tokenName;
    return <ul key={token.address}>
      <li
        className="bounded-text">Starting {tokenName} : {toDecimalPlaces(token.tokenStartingBalance)}</li>
      <li
        className="bounded-text">{renderIsEstimated(props.entry)}Current {tokenName}: {toDecimalPlaces(token.tokenCurrentBalance)}</li>
      <li className="">{renderIsEstimated(props.entry)}Net
        Gain: <PctGainLossSpan
          decimalScale={2}
          val={token.tokenCurrentBalance - token.tokenStartingBalance}>{tokenName}</PctGainLossSpan>
      </li>
      <li className="">{renderIsEstimated(props.entry)}Net Gain (USD): <PctGainLossSpan
        prefix="$"
        val={token.tokenUsdGain}></PctGainLossSpan>
      </li>
      {renderHr ? <span className="shim"/> : null}
    </ul>;
  }

  // there can be more than 2 coins in a pools, so this handles that case and displays it correct
  function renderTokenGainsLossesMoreThan2Pools(tokens) {
    const numTokensPerCol = Math.ceil(tokens.length / 2);
    const firstRow = tokens.slice(0, numTokensPerCol);
    const secondRow = tokens.slice(numTokensPerCol);

    return (
      <>
        <td className="f-w-700">
          {firstRow.map((token, idx, array) => {
              return renderToken(token, idx !== array.length - 1);
            },
          )}
        </td>
        <td className="f-w-700">
          {secondRow.map((token, idx, array) => {
              return renderToken(token, idx !== array.length - 1);
            },
          )}
        </td>
      </>
    );
  }

  function renderTokenGainsLossesPair(tokens) {
    return (
      <>
        {tokens.map((token) =>
          <td className="f-w-700" key={token.address}>
            {renderToken(token)}
          </td>,
        )}
      </>
    );
  }

  function renderExcludeModal() {
    return <Modal isOpen={excludeModal} className="welcome-popup modal-dialog-centered ">
      <button
        onClick={() => setExcludeModal(false)}
        className="close"
        type="button"
        data-dismiss="excludeModal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <ModalBody>
        <div className="contain p-30">
          <div className="text-center">
            <h3 className="mt-4">Exclude Liquidity Pool?</h3>
            <p>You can exclude this pair from your calculations if you know you don't have a
              position in this liquidity pool anymore. Please be aware that since we don't store
              your account information on the server, you will need to manually exclude this again
              when you use a new browser or clear your local settings.</p>
            <button
              onClick={() => {
                props.setExcludedPoolAddresses(props.entry.address);
                setExcludeModal(false);
              }}
              className="btn btn-primary btn-lg txt-white btn-pill"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              Exclude {props.entry.name}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>;
  }

  function maybeRenderExclude(entry) {
    if (floorFloat(entry.mintBurntLedgerLpTokens) - floorFloat(entry.currentOwnedLpTokens) !== 0) {
      return <a onClick={() => setExcludeModal(true)}
                className="flex-link mt-2 text font-muted"><MinusCircle/> <span
        className="text-action ml-1">Exclude</span></a>;
    }
    return null;
  }

  function getGainLossElement() {
    return props.gainsMode === 0 ? <PctGainLossSpan
      prefix="$"
      val={props.entry.netGainUsd}
      className="large">
      <br/>{getGainPctStr(props.entry.netGainPct, true)}
    </PctGainLossSpan> : <PctGainLossSpan
      prefix="$"
      className="large"
      val={props.entry.totalValueUsd - props.entry.initialCapitalValueUsd}><br/>{getGainPctStr((((props.entry.totalValueUsd - props.entry.initialCapitalValueUsd) / props.entry.initialCapitalValueUsd) * 100), true)}
    </PctGainLossSpan>;
  }

  function maybeRenderError() {
    const entry = props.entry.tokens.find((element) => element.tokenCurrentPrice === 0);
    return entry ? <i className="fa fa-warning"
                      title="There has been a problem with getting data. Please click on More Info to see details."/> : null;
  }

  function maybeRenderNewItemBadge() {
    return props.entry.poolProviderName === 'Uniswap' ? <span className="badge badge-pill badge-primary">New</span> : null;
  }

  return (
    <tr className="portfolio-entry">
      <td>
        <div className="recent-images">
          <img
            className="img-fluid"
            src={getPoolIcon(props.entry.poolProviderName)}
            alt=""
          />
        </div>
      </td>
      <td>
        <h5 className="default-text mb-0 f-w-700 f-18 pool-name">
          <a href={getPoolInformation(props.entry)}
             target="_blank">{props.entry.name.split('/')[0]}-{props.entry.name.split('/')[1]}</a>
          {maybeRenderError()}
        </h5>
      </td>
      {props.entry.tokens.length > 2 ? renderTokenGainsLossesMoreThan2Pools(props.entry.tokens) : renderTokenGainsLossesPair(props.entry.tokens)}
      <td className="centered">
        <h5 className="f-w-700 large tml">
          <CurrencyFormat value={props.entry.totalValueUsd} displayType="text"
                          thousandSeparator prefix="$" decimalScale={2}/>
        </h5>
        {getGainLossElement()}
      </td>
      <td className="pl-5">
        {maybeRenderNewItemBadge()}
        <a onClick={toggle} className="flex-link"><Info/> <span className="text-action ml-1">More Info</span></a>
        {maybeRenderExclude(props.entry)}
      </td>

      <GainLossDetailModal entry={props.entry} modal={modal} toggle={toggle}
                           setExcludeModal={setExcludeModal}
                           provider={props.provider}/>

      {renderExcludeModal()}
    </tr>
  );
}
