/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container, Form,
  FormGroup, Input,
  Label,
  Row,
} from 'reactstrap';
import PortfolioStats from '../PortfolioStats';
import YourLiquidityPools from '../YourLiquidityPools';
import { getPoolIcon } from '../../helpers/util';

export default function(props) {
  const [userInputAddrs, setUserInputAddrs] = useState('');

  function setExcludedPoolAddresses(excludedAddrs) {
    try {
      const key = `excluded_pools_${userInputAddrs || props.addressFromWallet}`;
      const item = window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key)) : [];
      const uniqueAddrs = [...new Set([...item, excludedAddrs.toLowerCase()])];
      window.localStorage.setItem(key, JSON.stringify(uniqueAddrs));
      props.portfolio.pairInfos = [...props.portfolio.pairInfos.filter((pairInfo) => pairInfo.address !== excludedAddrs)];
      props.setPortfolio({ ...props.portfolio });
    } catch (err) {
      console.error(err);
    }
  }

  function showSignInButton() {
    return (<div className="mt-4 mb-3">
      <Row className="justify-content-center">
        <Col className="col-7">
          <Card>
            <CardHeader className="bg-info">
              <h5 className="centered">Select Exchanges</h5>
            </CardHeader>
            <CardBody className="select-exchanges">
              <Row>
                <Col>
                  <FormGroup
                    className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml justify-content-center">
                    <Label className="" for="chk-ani">
                      <Input className="checkbox_animated" id="chk-ani" type="checkbox"
                             defaultChecked/>
                      <img
                        className="img-fluid selector-img"
                        src={getPoolIcon('Uniswap')}
                        alt=""
                      />
                      Uniswap
                    </Label>
                    <Label className="" for="chk-ani2">
                      <Input className="checkbox_animated" id="chk-ani2" type="checkbox"
                             defaultChecked/>
                      <img
                        className="img-fluid selector-img"
                        src={getPoolIcon('Balancer')}
                        alt=""
                      />
                      Balancer
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup
                    className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml justify-content-center">
                    <Label className="" for="chk-ani3">
                      <Input className="checkbox_animated" id="chk-ani3" type="checkbox"
                             defaultChecked
                      />
                      <img
                        className="img-fluid selector-img"
                        src={getPoolIcon('Sushiswap')}
                        alt=""
                      />
                      Sushiswap
                    </Label>
                    <Label className="" for="chk-ani4">
                      <Input className="checkbox_animated" id="chk-ani4" type="checkbox"
                             disabled
                      />
                      <img
                        className="img-fluid selector-img"
                        src={getPoolIcon('VALUE')}
                        alt=""
                      />
                      VALUE (Coming Soon!)
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>;
      <Button onClick={() => {
        props.performSearchViaProvider();
      }} className="btn-lg btn-pill btn-air-info connect-btn" color="info">
        Connect Wallet
      </Button>
      <p className="centered mt-3">(we only read from your wallet and we never transact on your
        behalf)</p>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <h5 className="centered mt-4 mb-4">OR</h5>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await props.getInfo(userInputAddrs);
            }}
            className="form theme-form">

            <FormGroup className="mb-3">
              <Input value={userInputAddrs}
                     className="form-control input-air-primary custom addrs-manual"
                     onChange={(e) => setUserInputAddrs(e.target.value)}
                     placeholder="enter address"/>
            </FormGroup>
            <Button className="btn-lg btn-pill btn-air-info connect-btn"
                    disabled={props.viewButtonText === 'Loading...'}
                    color="info">{props.viewButtonText}</Button>

          </Form>
        </Col>
      </Row>
    </div>);
  }

  return (<>
      <Row>
        <Col xl="12">
          <Card className="card-absolute">
            <CardHeader className="bg-primary">
              <h5>Your Liquidity Provider Gains / Losses</h5>

            </CardHeader>
            <CardBody className="small-padding">
              <Row>
                <Col xl="12 xl-100 box-col-12">
                  <PortfolioStats portfolio={props.portfolio}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen="true" className="redirect-popup modal-dialog-centered">
        <button
          className="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <ModalBody>
          <ModalHeader></ModalHeader>
          <div className="contain p-30">
            <div className="text-center">
              <h3>We are now <a href="https://apy.vision" target="_blank">APY.vision</a></h3>
              <p>We rebranded from Liquidity.Vision! Please use our new site instead. <b>Please do not
                use the old Discord server as it is not officially managed by our team anymore.
                Official annoucements are done only on the new Discord server.</b></p>
              <button
                onClick={() => open('https://discord.gg/ePfGAYbqUq', '_blank')}
                className="btn btn-primary btn-lg txt-white rounded-bg"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                Join New Discord
              </button>
              <br/>
              <br/>
              <button
                onClick={() => open('https://medium.com/@apyvision/weve-rebranded-to-apy-vision-8cd921919485', '_blank')}
                className="btn btn-primary btn-lg txt-white rounded-bg"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                Read Blog Post
              </button>
              <br/>
              <br/>
              <button
                onClick={() => open('https://apy.vision', '_blank')}
                className="btn btn-primary btn-lg txt-white rounded-bg"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                Proceed
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
