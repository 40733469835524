import React from "react";
import { timeAgoFromBlock, truncAddrs } from "../helpers/util";
import PctGainLossSpan from "./PctGainLossSpan";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useBlockNumber } from "eth-hooks";
import { InfuraProvider } from "@ethersproject/providers";
import { INFURA_ID } from "../constants";

const mainnetProvider = new InfuraProvider("mainnet", INFURA_ID);

export default function(props) {
  const currentBlockNumber = useBlockNumber(mainnetProvider, 900000);
  const entry = props.entry;

  function getEventMessage(tx) {
    if (tx.type === "transferLPIn" && tx.address === "0x0000000000000000000000000000000000000000") {
      return <h5 className="mt-0 inline">Entered Liquidity Pool</h5>;
    } else if (tx.type === "transferLPOut" && tx.address === "0x0000000000000000000000000000000000000000") {
      return <h5 className="mt-0 inline">Exited Liquidity Pool</h5>;
    } else if (tx.type === "transferLPOut" && tx.amount === 0) {
      return <h5 className="mt-0 inline">Claimed Rewards</h5>;
    } else if (tx.type === "transferLPIn") {
      return <h5 className="mt-0 inline">
        Received LP Tokens from <a className="anchor-link ml-1"
                                   onClick={() => window.open(`https://etherscan.io/tx/${tx.transactionHash}`, "_blank")}>
        {tx.contractName === null ? truncAddrs(tx.address) : tx.contractName} - {timeAgoFromBlock(currentBlockNumber, tx.blockNumber)}
      </a>
      </h5>;
    }

    return <h5 className="mt-0 inline">
      Sent LP Tokens to <a className="anchor-link"
                           onClick={() => window.open(`https://etherscan.io/tx/${tx.transactionHash}`, "_blank")}>
      {tx.contractName === null ? truncAddrs(tx.address) : tx.contractName} - {timeAgoFromBlock(currentBlockNumber, tx.blockNumber)}
    </a>
    </h5>;
  }

  function maybeRenderEnterExitPoolStats(tx) {
    if (tx.burnOrMintResult) {
      return (
        <>
          {Object.entries(tx.burnOrMintResult.amounts).map((token) => {
            return <><PctGainLossSpan
              className="small"
              val={tx.burnOrMintResult.isBurn ? token[1] : -token[1]}>{token[0]}</PctGainLossSpan>&nbsp;&nbsp;</>;
          })
          }
        </>
      );
    }
    return null;
  }

  function renderTxHistory() {
    return entry.txs.slice().reverse().map((tx) => <li className="media">
      <span
        className={`ml-3 mr-3 circle ${tx.type === "transferLPOut" ? "font-danger" : "font-success"}`}>{tx.type === "transferLPIn" ? "+" : "-"}</span>
      <div className="align-self-center media-body">
        {getEventMessage(tx)}
        <ul>
          <li>
            {maybeRenderEnterExitPoolStats(tx)}
          </li>
          <li>
            <PctGainLossSpan
              decimalScale={8}
              className="small"
              val={tx.type === "transferLPIn" ? tx.amount : -tx.amount}>LP
              Tokens</PctGainLossSpan>
          </li>
        </ul>
      </div>
    </li>);
  }

  return (
    <Card>
      <CardHeader className="no-border custom">
        <h5>Your Transaction History</h5>
      </CardHeader>
      <CardBody className="px-0 pt-0">
        <ul className="crm-activity small">
          {renderTxHistory()}
        </ul>

      </CardBody>
    </Card>
  );
}
