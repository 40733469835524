import { useCallback, useEffect, useState } from 'react';
import { useUserAddress } from 'eth-hooks';
import { useContractLoader, useUserProvider } from './index';
import { BigNumber } from '@ethersproject/bignumber';
import { formatEther } from '@ethersproject/units';

const getTokens = async (contract, wallet) => {
  try {
    const balance = await contract.balanceOf(wallet);
    return BigNumber.from(balance);
  } catch (e) {
    return BigNumber.from(0);
  }
};

export default function useGetLiquidityVisionBalance(provider) {
  const [visionBalance, setVisionBalance] = useState();
  const readContracts = useContractLoader(provider);
  const userProvider = useUserProvider(provider, null);
  const addressFromWallet = useUserAddress(userProvider);

  const fetchBalance = useCallback(async () => {
    const balance = await getTokens(readContracts.YourContract, addressFromWallet);
    setVisionBalance(parseFloat(formatEther(balance)));
  }, [readContracts, addressFromWallet]);

  useEffect(() => {
    if (provider && addressFromWallet) {
      fetchBalance();
    }
  }, [provider, addressFromWallet, readContracts]);
  return visionBalance;
}
