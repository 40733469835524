import React from "react";
export const MENUITEMS = [
  {
    title: "Home",
    icon: <i className="pe-7s-home pe-lg"></i>,
    path: `/`,
    type: "sub",
    active: true,
    bookmark: true,
    children: [
    ],
  },
];
