import React, { Fragment, useState } from "react";
import { Collapse, Container, Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import { HelpCircle } from "react-feather";

export default function(props) {
  const [isCollaps, setIsCollaps] = useState(false);
  const [isCollaps1, setIsCollaps1] = useState(false);
  const [isCollaps2, setIsCollaps2] = useState(false);
  const [isCollaps3, setIsCollaps3] = useState(false);
  const [isCollaps4, setIsCollaps4] = useState(false);
  const [isCollaps5, setIsCollaps5] = useState(false);
  const [isCollaps6, setIsCollaps6] = useState(false);
  const [isCollaps7, setIsCollaps7] = useState(false);
  const [isCollaps8, setIsCollaps8] = useState(false);
  const [isCollaps9, setIsCollaps9] = useState(false);
  const [isCollaps10, setIsCollaps10] = useState(false);

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="faq-wrap">
          <Row>
            <Col lg="12">
              <div className="header-faq">
                <h5 className="mb-0">Frequently Asked Questions</h5>
              </div>
              <Row
                className="default-according style-1 faq-accordion"
                id="accordionoc"
              >
                <div className="col-xl-10 xl-100 col-lg-10 col-md-10">
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps(!isCollaps)}
                          data-toggle="collapse"
                          data-target="#collapseicon"
                          aria-expanded={isCollaps}
                          aria-controls="collapseicon"
                        >
                          <HelpCircle/>
                          What is Liquidity.vision?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps}>
                      <CardBody>
                        Liquidity.vision is an all-in-one dashboard to manage your DeFi Liquidity
                        Pools and track your Impermanent Loss/Gain in real time. We support
                        different decentralize exchanges (ie: AMMs) such as Uniswap, Balancer, and
                        Sushiswap. More AMMs will be supported in the future.
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps1(!isCollaps1)}
                          data-toggle="collapse"
                          data-target="#collapseicon2"
                          aria-expanded={isCollaps1}
                          aria-controls="collapseicon2"
                        >
                          <HelpCircle/> What is a liquidity pool?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps1}>
                      <CardBody>
                        A Liquidity Pool is a way for you to provide liquidity (money) to a
                        Decentralized Exchange (DEX) such as Uniswap, Balancer, or Sushiswap for
                        them to operate their business in exchange for receiving a % of fees.
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps2(!isCollaps2)}
                          data-toggle="collapse"
                          data-target="#collapseicon3"
                          aria-expanded={isCollaps2}
                          aria-controls="collapseicon2"
                        >
                          <HelpCircle/>
                          What are the benefits / risks associated with providing liquidity to a
                          liquidity pool?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps2}>
                      <CardBody>
                        By providing liquidity to a pool (by contributing the pool's required
                        tokens), you are earning a fee from all the trades (aka swaps), usually at
                        0.3% of the trade. This is based on your proportional ownership of the pool.
                        However, the downside risk of providing liquidity is Impermanent Loss, which
                        is the difference of the coins between entering into a liquidity
                        pool position versus simply holding your coins in your wallet. As the
                        allocation of the coins in a pool changes based on supply and demand, you
                        can end up with more of the undesirable coin.
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps3(!isCollaps3)}
                          data-toggle="collapse"
                          data-target="#collapseicon4"
                          aria-expanded={isCollaps3}
                          aria-controls="collapseicon2"
                        >
                          <HelpCircle/> How does Liquidity.vision calculate Impermanent Loss/Gain?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps3}>
                      <CardBody>
                        We take the difference between your Initial Assets at Initial Prices
                        provided to the Liquidity Pool and your Current Assets at Current Prices.
                        This provides us with either your Impermanent Loss or Impermanent Gains
                        values.
                      </CardBody>
                    </Collapse>
                  </Card>
                  {/*<div className="faq-title">*/}
                  {/*  <h6>Intellectual Property</h6>*/}
                  {/*</div>*/}
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps4(!isCollaps4)}
                          data-toggle="collapse"
                          data-target="#collapseicon5"
                          aria-expanded={isCollaps4}
                        >
                          <HelpCircle/> How does Liquidity.vision calculate my portfolio?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps4}>
                      <CardBody>
                        <p>We track the entry and exits of your Liquidity Pool positions
                          themselves.</p>
                        <p><b><u>Please note that we will not have accurate results if you
                          have
                          been transferred LP
                          tokens by an outside party or have sent them to yourself from another
                          wallet
                          address or purchased LP tokens on an exchange.</u></b></p>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps5(!isCollaps5)}
                          data-toggle="collapse"
                          data-target="#collapseicon7"
                          aria-expanded={isCollaps5}
                          aria-controls="collapseicon2"
                        >
                          <HelpCircle/> Is my information private?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps5}>
                      <CardBody>
                        Yes. We do not store any identifying information or KYC users. Whether you
                        choose to
                        connect your wallet or input it manually your information remains private.
                        We do use Google Analytics to track overall site visits but we don't have
                        identifying information on our users.
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps6(!isCollaps6)}
                          data-toggle="collapse"
                          data-target="#collapseicon8"
                          aria-expanded={isCollaps6}
                          aria-controls="collapseicon2"
                        >
                          <HelpCircle/> Who is the team behind Liquidity.vision?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps6}>
                      <CardBody>
                        We are a small team of two known as @Tomacf and @dr.eggplant.
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps8(!isCollaps8)}
                          data-toggle="collapse"
                          data-target="#collapseicon10"
                          aria-expanded={isCollaps8}
                          aria-controls="collapseicon2"
                        >
                          <HelpCircle/>
                          How can I support Liquidity.vision?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps8}>
                      <CardBody>
                        Thank you for asking! You can donate to our Gitcoin at <a
                        href="https://gitcoin.co/grants/1268/liquidity-vision"
                        target="_blank">https://gitcoin.co/grants/1268/liquidity-vision </a>
                        <br/>
                        Or donate directly to: 0x7355581BB08b307B991A57D0d6F40Db3E60a34a2
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          color="btn btn-link collapsed pl-0"
                          onClick={() => setIsCollaps9(!isCollaps9)}
                          data-toggle="collapse"
                          data-target="#collapseicon11"
                          aria-expanded={isCollaps9}
                          aria-controls="collapseicon2"
                        >
                          <HelpCircle/>
                          Are you open to business inquiries?
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={isCollaps9}>
                      <CardBody>
                        Yes! Please message us on Twitter or join us in Discord and reach out to one
                        of us and we will be happy to discuss.
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
