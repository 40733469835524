import React, { useState } from 'react';
import PctGainLossSpan from './PctGainLossSpan';
import { Alert, Button, Card, CardBody, Col, Row, Tooltip } from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { ArrowDownRight, ArrowUpRight, ExternalLink, HelpCircle } from 'react-feather';
import TokenAddressAndPrice from './TokenAddressAndPrice';
import {
  displayPretty,
  getAddLiquidityLink, getGainPctStr,
  getPoolInformation,
  getRemoveLiquidityLink, getSwapLink,
  toDecimalPlaces,
} from '../helpers/util';

export default function(props) {
  const poolInfo = props.poolInfo;

  const [tooltip12, setTooltip12] = useState(false);
  const DirectionToggle12 = () => setTooltip12(!tooltip12);

  function getDifferenceAmt(currentPeriodValue, lastPeriodValue) {
    const pct = (((currentPeriodValue - lastPeriodValue) / lastPeriodValue) * 100).toFixed(2);
    const val = `$${displayPretty(lastPeriodValue)} USD`;
    const isGain = lastPeriodValue < currentPeriodValue ?
      <ArrowUpRight className="warning-icon" size={16}/> :
      <ArrowDownRight className="warning-icon" size={16}/>;
    return <span
      className={pct >= 0.00 ? 'accented font-success f-w-700' : 'accented font-danger f-w-700'}>{val} ({pct > 0.00 ? '+' : ''}{pct}%)
      {isGain}
    </span>;
  }

  function getDifferenceAmtInverse(currentPeriodValue, lastPeriodValue) {
    const pct = (((currentPeriodValue - lastPeriodValue) / lastPeriodValue) * 100).toFixed(2);
    const val = `$${displayPretty(lastPeriodValue)} USD`;
    return <span
      className={pct >= 0.00 ? 'accented font-danger f-w-700' : 'accented font-success f-w-700'}>{val} ({pct > 0.00 ? '+' : ''}{pct}%)</span>;
  }

  function getLiquidityAprSpan(currentPeriodValue, lastPeriodValue) {
    const pct = (((currentPeriodValue - lastPeriodValue) / lastPeriodValue) * 100).toFixed(2);
    const val = `${displayPretty(lastPeriodValue)}%`;
    const isGain = lastPeriodValue < currentPeriodValue ?
      <ArrowUpRight className="warning-icon" size={16}/> :
      <ArrowDownRight className="warning-icon" size={16}/>;
    return <span
      className={pct >= 0.00 ? 'accented font-success f-w-700' : 'accented font-danger f-w-700'}>
      {val}
      {isGain}
    </span>;
  }

  return (
    <>
      <Card className="btc-table">
        <CardBody className="pt-0">
          <div className="btc-sell text-center">
            <Alert className="alert-dismissible mt-3 mb-3"
                   color="info "
                   target="Alert-1"
            ><p>This is a Pro feature that is in preview - let us know if you have any
              comments!</p>
            </Alert>

            <h4 className="sm-padding">
              <a className="anchor-link text-center block"
                 onClick={() => window.open(getPoolInformation(poolInfo), '_blank')}>
                {poolInfo.name} Insights
                <ExternalLink className="external-link-icon"/>
              </a>
            </h4>
          </div>

          <div className="btc-sell text-center">
            <h5>Pool Information</h5>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Pool Age
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    {poolInfo.pool_age_days} Days
                  </div>
                </Col>
              </Row>
            </li>

          </ul>

          <div className="btc-sell text-center">
            <h5>1y Fees / Liquidity APY</h5>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Last 24 Hours (APY)
                  </div>
                </Col>
                <Col sm="6" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    {displayPretty(poolInfo.average_apys['1d'])}%
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 7 / 14 / 30 Day Avg (APY)
                    <a
                      id="Tooltip-12"
                      className="badge badge-pill badge-light tooltip-stats"
                    >
                      <HelpCircle/>
                      <Tooltip
                        placement="top"
                        isOpen={tooltip12}
                        target="Tooltip-12"
                        toggle={DirectionToggle12}
                        offset="20px,20px"
                      >
                        This is the annualized percentage rate on return from fees based on the
                        average fees collected since the period described.
                      </Tooltip>
                    </a>

                  </div>
                </Col>
                <Col sm="6" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 7 ? getLiquidityAprSpan(poolInfo.average_apys['1d'], poolInfo.average_apys['7d']) : '-'} &nbsp;/&nbsp;
                    {poolInfo.pool_age_days >= 14 ? getLiquidityAprSpan(poolInfo.average_apys['1d'], poolInfo.average_apys['14d']) : '-'} &nbsp;/&nbsp;
                    {poolInfo.pool_age_days >= 30 ? getLiquidityAprSpan(poolInfo.average_apys['1d'], poolInfo.average_apys['30d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>

            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Since Inception (APY)
                  </div>
                </Col>
                <Col sm="6" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    {getLiquidityAprSpan(poolInfo.average_apys['1d'], poolInfo.average_apys['inception'])}
                  </div>
                </Col>
              </Row>
            </li>
          </ul>

          <div className="btc-sell text-center">
            <h5>Pool Volume</h5>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Volume (Last 24 Hours)
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    ${displayPretty(poolInfo.avg_period_daily_volume_usd)} USD
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 7 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 7 ? getDifferenceAmt(poolInfo.avg_period_daily_volume_usd, poolInfo.average_volumes['7d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 14 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 14 ? getDifferenceAmt(poolInfo.avg_period_daily_volume_usd, poolInfo.average_volumes['14d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 30 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 30 ? getDifferenceAmt(poolInfo.avg_period_daily_volume_usd, poolInfo.average_volumes['30d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>

          </ul>

          <div className="btc-sell text-center">
            <h5>Pool Liquidity Reserves</h5>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Reserves
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    ${displayPretty(poolInfo.avg_period_reserve_usd)} USD
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 7 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 7 ? getDifferenceAmt(poolInfo.avg_period_reserve_usd, poolInfo.average_reserves['7d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 14 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 14 ? getDifferenceAmt(poolInfo.avg_period_reserve_usd, poolInfo.average_reserves['14d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 30 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 30 ? getDifferenceAmt(poolInfo.avg_period_reserve_usd, poolInfo.average_reserves['30d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>

          </ul>

          <div className="btc-sell text-center">
            <h5>Fees Collected</h5>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Fee Collected (Last 24 Hours)
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    ${displayPretty(poolInfo.avg_period_daily_fees)} USD
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 7 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 7 ? getDifferenceAmt(poolInfo.avg_period_daily_fees, poolInfo.average_fees_usd['7d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 14 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 14 ? getDifferenceAmt(poolInfo.avg_period_daily_fees, poolInfo.average_fees_usd['14d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 30 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 30 ? getDifferenceAmt(poolInfo.avg_period_daily_fees, poolInfo.average_fees_usd['30d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>

          </ul>

          <div className="btc-sell text-center">
            <h5>Impermanent Losses</h5>
          </div>
          <ul className="btc-buy-sell">
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    Impermanent Losses USD (Last 24 Hours)
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block">
                    ${displayPretty(poolInfo.avg_period_daily_imp_loss_usd)} USD
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 7 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 7 ? getDifferenceAmtInverse(poolInfo.avg_period_daily_imp_loss_usd, poolInfo.average_imp_losses_usd['7d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 14 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 14 ? getDifferenceAmtInverse(poolInfo.avg_period_daily_imp_loss_usd, poolInfo.average_imp_losses_usd['14d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col className="btc-table-xs">
                  <div className="btc-amount  f-w-700 text-center">
                    vs. Last 30 Day Avg
                  </div>
                </Col>
                <Col sm="5" className="p-l-0 btc-table-xs-l">
                  <div className="btc-amount f-w-700 text-center block info-box">
                    {poolInfo.pool_age_days >= 30 ? getDifferenceAmtInverse(poolInfo.avg_period_daily_imp_loss_usd, poolInfo.average_imp_losses_usd['30d']) : '-'}
                  </div>
                </Col>
              </Row>
            </li>

          </ul>

        </CardBody>
      </Card>
    </>
  );
}
