import React, { useState } from "react";
import { Card, CardBody, Col, Row, Tooltip } from "reactstrap";
import { BarChart, BarChart2, HelpCircle, Smile } from "react-feather";
import CurrencyFormat from "react-currency-format";
import { getGainPctStr } from "../helpers/util";

export default function(props) {

  const [tooltip1, setTooltip1] = useState(false);
  const DirectionToggle1 = () => setTooltip1(!tooltip1);
  const [tooltip2, setTooltip2] = useState(false);
  const DirectionToggle2 = () => setTooltip2(!tooltip2);
  const [tooltip3, setTooltip3] = useState(false);
  const DirectionToggle3 = () => setTooltip3(!tooltip3);
  const [tooltip4, setTooltip4] = useState(false);
  const DirectionToggle4 = () => setTooltip4(!tooltip4);

  return (
    <Row>
      <Col xl="3">
        <Card className="gradient-grey o-hidden main-metric">
          <CardBody className="b-r-4 relative">
            <a
              id="Tooltip-1"
              className="badge badge-pill badge-light tooltip-stats"
            >
              <HelpCircle/>
              <Tooltip
                placement="top"
                isOpen={tooltip1}
                target="Tooltip-1"
                toggle={DirectionToggle1}
                offset="20px,20px"
              >
                This is your current total USD value of the assets you hold in liquidity pools.
              </Tooltip>
            </a>

            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <BarChart/>
              </div>
              <div className="media-body">
                                    <span
                                      className="m-0">Total Market Value (USD)
                                    </span>
                <h4
                  className="mb-0 mt-1 counter">
                  {props.portfolio ?
                    <CurrencyFormat value={props.portfolio.totalValueUsd} displayType={"text"}
                                    decimalScale={0}
                                    prefix="$" thousandSeparator/> : "--"}
                </h4>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3">
        <Card className="gradient-grey o-hidden main-metric">
          <CardBody className="b-r-4 relative">
            <a
              id="Tooltip-2"
              className="badge badge-pill badge-light tooltip-stats"
            >
              <HelpCircle/>
              <Tooltip
                placement="top"
                isOpen={tooltip2}
                target="Tooltip-2"
                toggle={DirectionToggle2}
                offset="20px,20px"
              >
                This includes all the costs of your past transaction fees to enter your positions as
                well as the future transaction costs to exit your positions.
              </Tooltip>
            </a>
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <BarChart2/>
              </div>
              <div className="media-body">
                <span className="m-0">Gas Fees + Exit Transactions</span>
                <h4
                  className="mb-0 mt-1 counter">
                  {props.portfolio ?
                    <CurrencyFormat value={props.portfolio.totalFeeUsd} displayType={"text"}
                                    decimalScale={2}
                                    prefix="$" thousandSeparator/> : "--"}</h4>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3">
        <Card className="gradient-grey o-hidden main-metric">
          <CardBody className="b-r-4 relative">
            <a
              id="Tooltip-3"
              className="badge badge-pill badge-light tooltip-stats"
            >
              <HelpCircle/>
              <Tooltip
                placement="top"
                isOpen={tooltip3}
                target="Tooltip-3"
                toggle={DirectionToggle3}
                offset="20px,20px"
              >
                This includes all the costs of your past transaction fees to enter your positions as
                well as the future transaction costs to exit your positions. This figure excludes
                market price changes from since you entered the pool.
              </Tooltip>
            </a>
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <BarChart2/>
              </div>
              <div className="media-body">
                <span className="m-0 ">Liquidity Pool Gains (USD)</span>
                <h4
                  className="mb-0 mt-1 counter">
                  {props.portfolio ?
                    <CurrencyFormat value={props.portfolio.netGainUsd} displayType={"text"}
                                    decimalScale={2}
                                    prefix="$" thousandSeparator/> : "--"}</h4>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl="3">
        <Card className="gradient-grey o-hidden main-metric">
          <CardBody className="b-r-4 relative">
            <a
              id="Tooltip-4"
              className="badge badge-pill badge-light tooltip-stats"
            >
              <HelpCircle/>
              <Tooltip
                placement="top"
                isOpen={tooltip4}
                target="Tooltip-4"
                toggle={DirectionToggle4}
                offset="20px,20px"
              >
                This is your Net Impermanent Gain/Loss % based on your Liquidity Pool Gain (USD) value.
              </Tooltip>
            </a>
            <div className="media static-top-widget">
              <div className="align-self-center text-center">
                <Smile/>
              </div>
              <div className="media-body">
                <span className="m-0 ">Liquidity Pool Gain (%)</span>
                <h4
                  className="mb-0 mt-1 counter">{props.portfolio && props.portfolio.netGainPct ? getGainPctStr(props.portfolio.netGainPct) : "--"}</h4>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
