import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = (props) => {
  return (
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col md="6" className="footer-copyright">
            <p className="mb-0">© 2020. Made with the awesome <a
              href="https://github.com/austintgriffith/scaffold-eth" target="_blank">scaffold-eth
              template</a>.</p>
          </Col>
          <Col md="6">
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
