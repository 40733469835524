import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { UNISWAP_INFO_API_URL } from '../constants';
import { Table } from 'reactstrap';
import { displayPretty } from '../helpers/util';
import { AlertTriangle, ArrowDownRight, ArrowUpRight, HelpCircle, Music } from 'react-feather';
import moment from 'moment';
import { PairInfo } from './PairInfo';

function getDate() {
  var yesterday = new Date(Date.now() - 86400000); // that is: 24 * 60 * 60 * 1000
  var d = new Date(yesterday),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export default function(props) {

  const [backtestingData, setBacktestingData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${UNISWAP_INFO_API_URL}/api/v1/leaderboard/client`);
      const d = new Date(parseInt(response.data.last_updated, 10) * 1000);
      setLastUpdated(moment(d).fromNow());
      setBacktestingData(response.data.results);
    };

    fetchData();
  }, []);

  const renderLastApySpan = (apyOverall, apyForPeriod) => {
    const isGain = apyOverall < apyForPeriod ? <ArrowUpRight className="warning-icon" size={16}/> :
      <ArrowDownRight className="warning-icon" size={16}/>;
    return <>
      <span>${apyForPeriod.toFixed(2)}%</span>
      {isGain}
    </>;
  };

  return (<>
      <p className="font-weight-bold pools-count">{backtestingData.length} Results - Last
        Updated {lastUpdated}</p>
      <Table className="charts">
        <thead className="">
        <tr>
          <th scope="col" className="col sm">Rank</th>
          <th scope="col" className="col sm">Pool Name
          </th>
          <th scope="col" className="col xs">Pool Age (Days)</th>
          <th scope="col" className="col sm">
            Volume 24H (USD)
          </th>
          <th scope="col" className="col sm">
            Pool Reserve (USD)
          </th>
          <th scope="col" className="col md">
            Impermanent Loss 24H
          </th>
          <th scope="col" className="col sm">
            Fee Collected 24H
          </th>
          <th scope="col" className="col md">
            1y Fees / Liquidity Since Inception
            <a onClick={() => props.setHelpModalOpen(true)}>
              <HelpCircle size="20" className="help-bubble"/>
            </a>
          </th>
          <th scope="col" className="col md">
            vs. Last 7/14/30 Day Avg
            <a onClick={() => props.setHelpModalOpen(true)}>
              <HelpCircle size="20" className="help-bubble"/>
            </a>
          </th>
          <th scope="col">More Info</th>
        </tr>
        </thead>
        <tbody>
        {backtestingData.map((pair, index) => {
          const impLossUsd = pair.average_imp_losses_usd['1d'];
          const feeCollectedUsd = pair.average_fees_usd['1d'].toFixed(2);
          const inceptionAverageFeesApy = pair.average_apys.inception;
          const age = pair.pool_age_days;
          return (
            <tr key={pair.pool_address}>
              <td className="col sm">{index + 1}</td>
              <td className="col sm">
                <b className="">
                  <a href={`https://info.uniswap.org/pair/${pair.pool_address}`} target="_blank">
                    {pair.name.length > 15 ? `${pair.name.substring(0, 15)}...` : pair.name}
                  </a>
                </b>
              </td>
              <td><b className="accented">{age}</b>
              </td>
              <td>
                <b className="accented">{displayPretty(pair.avg_period_daily_volume_usd)}</b>
              </td>
              <td>
                <b className="accented">{displayPretty(pair.avg_period_reserve_usd)}</b>
              </td>
              <td>
                <b className="accented font-danger f-w-700">
                  ${impLossUsd.toFixed(2)}
                  {-impLossUsd > feeCollectedUsd ?
                    <AlertTriangle className="warning-icon" size={16}/> : null}
                </b>
              </td>
              <td>
                <b
                  className="accented font-success f-w-700">${feeCollectedUsd}</b>
              </td>
              <td>
                <b className="accented">{inceptionAverageFeesApy.toFixed(2)}%</b>
              </td>
              <td>
                <b className="accented">
                  {age >= 7 ? renderLastApySpan(inceptionAverageFeesApy, pair.average_apys['7d']) : '-'}
                  <br/>
                  {age >= 14 ? renderLastApySpan(inceptionAverageFeesApy, pair.average_apys['14d']) : '-'}
                  <br/>
                  {age >= 30 ? renderLastApySpan(inceptionAverageFeesApy, pair.average_apys['30d']) : '-'}
                </b>
              </td>
              <td>
                <PairInfo poolInfo={pair}/>
              </td>
            </tr>
          );
        })}
        </tbody>
      </Table>
    </>
  );
}
