import React, {
  useState,
} from 'react';
import { Button, Card, Col } from 'reactstrap';
import { RefreshCcw, Twitch, Twitter } from 'react-feather';
import { GasGauge } from '../../index';
import { useGasPrice } from '../../../hooks';
import Login from '../../Login';
import { useLocalStorage } from '../../../helpers/util';
import '../../../styles.css';
import SwitchMode from '../../SwitchMode';

const Header = (props) => {
  const [accessToken] = useLocalStorage('accessToken');

  const gasPrice = useGasPrice();

  function showConnectBtn() {
    if (accessToken) {
      return null;
    }
    return <li className="onhover-dropdown">
    </li>;
  }

  function getRefreshCcw() {
    return <div className="gradient-grey o-hidden main-metric rounded-bg">
      <a className="nav-link" onClick={props.refreshPorfolio}>
        <RefreshCcw/>
      </a>
    </div>;
  }

  return (
    <div className="page-main-header">
      <div className="main-header-right">
        <div className="main-header-left text-center">
          <div className="logo-wrapper">
            <h2 className="text-title">
              <img src="https://s3-us-west-2.amazonaws.com/acf-uploads/Image_uupMLaxKXt.png"
                   className="logo mr-3"/>
              Liquidity.vision 🌊
              <GasGauge gasPrice={gasPrice}/>
            </h2>
          </div>
        </div>
        <div className="nav-right col pull-right right-menu">
          <ul className="nav-menus">
            {props.hasResults && getRefreshCcw()}
            {showConnectBtn()}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
