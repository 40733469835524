import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";

const ChangeLogPage = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col xl="12 xl-60">

            <Card className="ribbon-vertical-left-wrapper">
              <div className="ribbon ribbon-bookmark ribbon-vertical-left ribbon-secondary">
                <i className="icofont icofont-tack-pin"></i>
              </div>
              <div className="job-search">
                <CardBody>
                  <Media>
                    <img
                      className="img-40 img-fluid m-r-20"
                      src={require("../../assets/images/job-search/3.png")}
                      alt=""
                    />
                    <Media body>
                      <h6 className="f-w-600">
                        <a href="#javascript">Version 0.7.0</a>
                        <span className="pull-right">Nov 10, 2020</span>
                      </h6>
                    </Media>
                  </Media>
                  <p className="mt-3 ml-5">
                    <ul style={{ listStyle: "inside" }}>
                      <li>We've revamped the Top Pools page to include better stats!</li>
                      <li>Under More Info, you'll also see pool information for your pools.</li>
                      <li>We've added a dark mode switch (PRO feature).
                      </li>
                    </ul>
                  </p>
                </CardBody>
              </div>
            </Card>
            <Card className="ribbon-vertical-left-wrapper">
              <div className="job-search">
                <CardBody>
                  <Media>
                    <img
                      className="img-40 img-fluid m-r-20"
                      src={require("../../assets/images/job-search/3.png")}
                      alt=""
                    />
                    <Media body>
                      <h6 className="f-w-600">
                        <a href="#javascript">Version 0.6.0</a>
                        <span className="pull-right">Oct 28, 2020</span>
                      </h6>
                    </Media>
                  </Media>
                  <p className="mt-3 ml-5">
                    <ul style={{ listStyle: "inside" }}>
                      <li>Fixed an issue with the Exclude not showing up.</li>
                      <li>Also added a manual Exclude Pool button for users.</li>
                      <li>Remove default checked checkbox for Sushiswap (takes a little extra longer
                        to load when checked).
                      </li>
                    </ul>
                  </p>
                </CardBody>
              </div>
            </Card>
            <Card className="ribbon-vertical-left-wrapper">
              <div className="job-search">
                <CardBody>
                  <Media>
                    <img
                      className="img-40 img-fluid m-r-20"
                      src={require("../../assets/images/job-search/3.png")}
                      alt=""
                    />
                    <Media body>
                      <h6 className="f-w-600">
                        <a href="#javascript">Version 0.5.8</a>
                        <span className="pull-right">Oct 20, 2020</span>
                      </h6>
                    </Media>
                  </Media>
                  <p className="mt-3 ml-5">
                    <ul style={{ listStyle: "inside" }}>
                      <li>Added the FAQ Page for some of our new members.</li>
                      <li>Added a Swap button for ease of use (thanks vfat on Discord for the
                        suggestion).
                      </li>
                      <li>Added a Charts Page with the best pools from the past 14 days. We will be
                        adding charts for 7 and 30, 60 days soon.
                      </li>
                    </ul>
                  </p>
                </CardBody>
              </div>
            </Card>

          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ChangeLogPage;
