import moment from 'moment';
import { useState } from 'react';

function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

export const floorFloat = (value) => decimalAdjust('floor', value, -5);

export function truncAddrs(addrs) {
  return `${addrs.substring(0, 5)}..${addrs.substring(addrs.length - 3)}`;
}

export function displayPretty(number) {
  if (number > 1000000000.0) {
    return `${(number / 1000000000.0).toFixed(2)}B`;
  } else if (number > 1000000.0) {
    return `${(number / 1000000.0).toFixed(2)}M`;
  } else if (number > 1000.0) {
    return `${(number / 1000.0).toFixed(0)}K`;
  } else {
    return `${number.toFixed(2)}`;
  }
}

export function timeAgoFromBlock(currentBlock, block) {
  // each block is ~13.2 seconds, we'll extrapolate this
  const secsAgo = (currentBlock - block) * 13.2;
  return moment(new Date().getTime() - (secsAgo * 1000)).fromNow();
}

function tokenAddress(addrs) {
  if (addrs.toLowerCase() === '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase()) {
    return 'ETH';
  }
  return addrs;
}

export function getSwapLink(entry) {
  switch (entry.poolProviderName) {
    case 'Uniswap':
      return `https://app.uniswap.org/#/swap?inputCurrency=${tokenAddress(entry.tokens[0].tokenAddress)}&outputCurrency=${tokenAddress(entry.tokens[1].tokenAddress)}`;
    case 'Balancer':
      return `https://pools.balancer.exchange/#/pool/${entry.address}/`;
    case 'Sushiswap':
      return `https://exchange.sushiswapclassic.org/#/swap?inputCurrency=${tokenAddress(entry.tokens[0].tokenAddress)}&outputCurrency=${tokenAddress(entry.tokens[1].tokenAddress)}`;
    default:
      throw new Error(`Unable to parse ${entry.poolProviderName}`);
  }
}

export function getAddLiquidityLink(entry) {
  switch (entry.poolProviderName) {
    case 'Uniswap':
      return `https://app.uniswap.org/#/add/${tokenAddress(entry.tokens[0].tokenAddress)}/${tokenAddress(entry.tokens[1].tokenAddress)}`;
    case 'Balancer':
      return `https://pools.balancer.exchange/#/pool/${entry.address}/`;
    case 'Sushiswap':
      return `https://exchange.sushiswapclassic.org/#/add/${tokenAddress(entry.tokens[0].tokenAddress)}/${tokenAddress(entry.tokens[1].tokenAddress)}`;
    default:
      throw new Error(`Unable to parse ${entry.poolProviderName}`);
  }
}

export function getRemoveLiquidityLink(entry) {
  switch (entry.poolProviderName) {
    case 'Uniswap':
      return `https://app.uniswap.org/#/remove/${tokenAddress(entry.tokens[0].tokenAddress)}/${tokenAddress(entry.tokens[1].tokenAddress)}`;
    case 'Balancer':
      return `https://pools.balancer.exchange/#/pool/${entry.address}/`;
    case 'Sushiswap':
      return `https://exchange.sushiswapclassic.org/#/remove/${tokenAddress(entry.tokens[0].tokenAddress)}/${tokenAddress(entry.tokens[1].tokenAddress)}`;
    default:
      throw new Error(`Unable to parse ${entry.poolProviderName}`);
  }
}

export function getTokenInformation(poolProviderName, address) {
  switch (poolProviderName) {
    case 'Uniswap':
      return `https://uniswap.info/token/${address}`;
    case 'Balancer':
      return `https://pools.balancer.exchange/#/pool/${address}/`;
    case 'Sushiswap':
      return `https://sushiswap.vision/token/${address}`;
    default:
      throw new Error(`Unable to parse ${poolProviderName}`);
  }
}

export function getPoolInformation(entry) {
  const addrs = entry.address || entry.pool_address;
  switch (entry.poolProviderName) {
    case 'Uniswap':
      return `https://uniswap.info/pair/${addrs}`;
    case 'Balancer':
      return `https://pools.balancer.exchange/#/pool/${addrs}/`;
    case 'Sushiswap':
      return `https://sushiswap.vision/pair/${addrs}`;
    default:
      return `https://uniswap.info/pair/${addrs}`;
  }
}

export function getPoolIcon(poolProviderName) {
  switch (poolProviderName) {
    case 'Uniswap':
      return 'https://s3-us-west-2.amazonaws.com/acf-uploads/Image_v6Q0lb4YHZ.png';
    case 'Balancer':
      return 'https://s3-us-west-2.amazonaws.com/acf-uploads/Image_4kLvxDcihi.png';
    case 'Sushiswap':
      return 'https://s3-us-west-2.amazonaws.com/acf-uploads/sushiswap-logo.png';
    case 'VALUE':
      return 'https://s3-us-west-2.amazonaws.com/acf-uploads/valuelogo.png';
    default:
      throw new Error(`Unable to parse ${poolProviderName}`);
  }
}

export function toDecimalPlaces(number) {
  const num = Math.abs(number);
  if (num === 0) {
    return 0.00;
  }
  if (num > 1) {
    return num.toFixed(2);
  }
  if (num > 0.1) {
    return num.toFixed(3);
  }
  if (num > 0.01) {
    return num.toFixed(4);
  }
  if (num > 0.001) {
    return num.toFixed(5);
  }
  if (num > 0.0001) {
    return num.toFixed(6);
  }
  return num.toFixed(13);
}

export function useLocalStorage(key, initialValue = null) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      console.error(err);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.error(err);
    }
  };

  return [storedValue, setValue];
}

export function getGainPctStr(pct, includeParenthsis = false) {
  // eslint-disable-next-line no-nested-ternary
  if (parseFloat(pct) <= -100) {
    return includeParenthsis ? '' : '--';
  }
  return includeParenthsis ? `(${pct.toFixed(2)}%)` : `${pct.toFixed(2)}%`;
}

export function getErrorFromCode(errCode) {
  switch (errCode) {
    case 'ADDRESS_MISMATCH':
      return 'Whoops -- your address does not match the address that signed the message.';
    case 'NO_TOKEN_IN_WALLET':
      return 'Whoops -- you don\'t have any Vision tokens on this account. Please acquire Vision tokens or connect an account with Vision tokens.';
    case 'SERVER_BUSY':
      return 'Whoops! Server is busy. Please try again or become a Pro member to get priority access.';
    default:
      return 'General Error. Please contact support.';
    // code block
  }
}
