import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";
import {
  HashRouter as Router,
  useLocation,
} from "react-router-dom";

function usePageViews() {
  const location = useLocation();
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
}

function Wrapper() {
  usePageViews();
  return <App/>;
}

ReactGA.initialize("UA-178674671-1");

ReactDOM.render(
  <Router>
    <Wrapper/>
  </Router>,
  document.getElementById("root"),
);
