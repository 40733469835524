import React, { useState } from 'react';
import {
  Collapse,
  Button,
  Card,
  CardBody, CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import {
  displayPretty,
} from '../../helpers/util';
import { HelpCircle } from 'react-feather';
import { Link } from 'react-router-dom';

export function TopPoolsHelpModal(props) {
  return (
    <>
      <Modal contentClassName="transparent" toggle={() => props.setModalOpen(!props.isOpen)}
             isOpen={props.isOpen}>
        <ModalBody>
          <Card className="card-absolute">
            <CardHeader className="bg-primary">
              <h5>FAQ</h5>
            </CardHeader>
            <CardBody className="small-padding">

              <Col lg="12">
                <Row className="default-according style-1 faq-accordion m-t-50">
                  <Card> <CardHeader className="bg-info"><h5
                    className="mb-0">
                    What is 1y Fees / Liquidity APY Since Inception metric?
                  </h5>
                  </CardHeader>
                    <CardBody className="small-padding">
                      <h6 className="m-t-10">
                        This takes the <b>average</b> daily fees collected since the beginning of
                        the pool, then divides it by the <b>average</b> daily liquidity reserves
                        time 365 (one year).
                        This is the return of the pool factoring in just the fees. Please note that
                        this does not include any impermanent losses, or losses in the USD value in
                        one or both tokens during the period.
                        <br/>
                        <br/>
                        The vs. Last 7/14/30 Day Avg stat is calculated based on the average of the
                        last x days and getting the APY from that average. This is done to compare
                        the stability of the APY.
                      </h6>
                    </CardBody>
                  </Card>
                </Row>
                <Row className="default-according style-1 faq-accordion m-t-50">
                  <Card> <CardHeader className="bg-info"><h5
                    className="mb-0">
                    Can I filter by different search criteria?
                  </h5>
                  </CardHeader>
                    <CardBody className="small-padding">
                      <h6 className="m-t-10">
                        Yes but please note that this is a Pro feature only. For more information
                        on how to become a pro member, please go
                        <Link
                          className="bar-icons"
                          to="/pricing"
                        > here </Link>.
                      </h6>
                    </CardBody>
                  </Card>
                </Row>
                <Row className="default-according style-1 faq-accordion m-t-50">
                  <Card>
                    <CardHeader className="bg-info"><h5
                      className="mb-0">
                      Is there a guarantee of returns by providing liquidity?
                    </h5>
                    </CardHeader>
                    <Collapse isOpen="true">
                      <CardBody className="small-padding">
                        <h6 className="m-t-10">
                          No. Investing in these pools will not guarantee you a investment return.
                          Pool liquidity changes from day to day and we try our best to surface the
                          consistent performers. Please do your own due diligence and research when
                          providing liquidity. Please note this is for reference only and this
                          is not investment advice.
                        </h6>
                      </CardBody>
                    </Collapse>
                  </Card>
                </Row>
                <Row className="default-according style-1 faq-accordion m-t-50">
                  <Card>
                    <CardHeader className="bg-info"><h5
                      className="mb-0">
                      How old is the data?
                    </h5>
                    </CardHeader>
                    <Collapse isOpen="true">
                      <CardBody className="small-padding">
                        <h6 className="m-t-10">
                          The data is updated daily. Please check back daily!
                        </h6>
                      </CardBody>
                    </Collapse>
                  </Card>
                </Row>
              </Col>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
}
