import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import PoolStatsPane from '../PoolStatsPane';

export default function(props) {
  const poolInfo = props.poolInfo;
  const [basicLineTab, setBasicLineTab] = useState('1');

  return (
          <Modal isOpen={props.modal} className="welcome-popup modal-dialog-centered"
                 contentClassName="uniswap"
                 toggle={props.toggle} size="md">
            <button onClick={props.toggle} className="close" type="button" data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <ModalBody>
              <ModalHeader/>

              <TabContent activeTab={basicLineTab}>
                <TabPane className="fade show" tabId="1">
                  <PoolStatsPane poolInfo={poolInfo}/>
                </TabPane>
              </TabContent>

            </ModalBody>
          </Modal>
  );
}
